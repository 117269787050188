import React, { useEffect, useRef, useState } from "react"
import { colors } from "../../styles/globals"
import { postPreparePaymentData } from "../../utils/api/payments"
import {
  getFacturi,
  getSoldClient,
  getUltimaPlata,
} from "../../utils/api/sivap"
import { getContracts, getUserInfo } from "../../utils/services/keepAuth"
import PaymentForm from "../PaymentForm"
import Spinner from "../Spinner"
import { splitFacturi } from "../app/facturi"
import { getNrFacturiStinse } from "../app/facturi/TabelRestante"
import "./style.css"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../Button"

export default function InfoClient({
  setShowPaymentRedirectLoading,
  containerClassName = "",
}) {
  const [sold, setSold] = useState(null)
  const [ultimaPlata, setUltimaPlata] = useState(null)
  const [userInfo, setUserInfo] = useState(null)

  const formRef = useRef()
  const jsonRequestRef = useRef()
  const checksumRef = useRef()

  const fetchSoldClient = async () => {
    try {
      const soldResponse = await getSoldClient()
      // console.log(soldResponse)
      // console.log(typeof soldResponse.data.sold)
      if (soldResponse.type) setSold(soldResponse.data.sold)
    } catch (error) {
      console.log(error)
      setSold("Indisponibil")
    }
  }

  const fetchUltimaPlata = async () => {
    try {
      const ultimaPlataResponse = await getUltimaPlata()
      // console.log(ultimaPlataResponse)
      if (ultimaPlataResponse.type)
        setUltimaPlata(ultimaPlataResponse.data.ultimaPlata)
    } catch (error) {
      console.log(error)
      setUltimaPlata("Indisponibil")
    }
  }

  useEffect(() => {
    // console.log("MOUNT INFOCLIENT")
    fetchSoldClient()
    fetchUltimaPlata()
    if (getUserInfo()) setUserInfo(getUserInfo())
  }, [])

  const makePayment = async () => {
    try {
      let nrFacReferintaPlata
      const facturiApiResponse = await getFacturi(getContracts()[0]._id)
      if (facturiApiResponse.type) {
        const [restante, achitate] = splitFacturi(facturiApiResponse.data)
        if (restante.length > 0)
          nrFacReferintaPlata = {
            nrFac: restante[restante.length - 1].nr_factura,
            idDocei: restante[restante.length - 1].id_factura,
          }
        else
          nrFacReferintaPlata = {
            nrFac: achitate[0].nr_factura,
            idDocei: achitate[0].id_factura,
          }

        const apiResponse = await postPreparePaymentData({
          suma: sold,
          descriere: "Plată sold întreg facturi.",
          facturiPlatite: getNrFacturiStinse(
            restante,
            sold,
            nrFacReferintaPlata
          ),
          ...userInfo,
        })
        if (apiResponse.type) {
          jsonRequestRef.current.value = apiResponse.data.base64JsonRequest
          checksumRef.current.value = apiResponse.data.base64Checksum
          setShowPaymentRedirectLoading(true)
          formRef.current.submit()
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  if (userInfo != null && userInfo.denumire != null)
    return (
      <div className={containerClassName + " icContainer"}>
        <div className="icTitle capitalizeText">
          {userInfo ? userInfo.denumire.toLowerCase() : "Indisponibil"}
        </div>
        <div className="infoClientRow">
          <div className="infoClientLabel">Cod client</div>
          <div className="infoClientText">
            {userInfo ? userInfo.codClient : "Indisponibil"}
          </div>
        </div>
        <div className="infoClientRow">
          <div className="infoClientLabel">Balanță</div>
          <div className="infoClientText">
            {sold !== null &&
              sold.toLocaleString("ro-RO", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            {sold === null && (
              <Spinner
                isDark={false}
                containerStyle={styles.spinner}
                show={sold === null}
              />
            )}
          </div>
        </div>
        <div className="infoClientRow">
          <div className="infoClientLabel">Ultima plată</div>
          <div className="infoClientText">
            {ultimaPlata !== null &&
              ultimaPlata.toLocaleString("ro-RO", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            {ultimaPlata === null && (
              <Spinner
                isDark={false}
                containerStyle={styles.spinner}
                show={ultimaPlata === null}
              />
            )}
          </div>
        </div>
        {sold > 0 && (
          <PaymentForm
            formClassName="infoClientRow infoClientAvansForm"
            formRef={formRef}
            jsonRequestRef={jsonRequestRef}
            checksumRef={checksumRef}
            buttonClassName="subMenuHover infoClientAvansButton"
            // buttonStyles={styles.payButton}
            disabled={false}
            buttonLabel={"Plătește sold"}
            makePayment={makePayment}
          />
        )}

        {/* <PaymentForm
        formRef={formRef}
        jsonRequestRef={jsonRequestRef}
        checksumRef={checksumRef}
        buttonClassName="subMenuHover"
        buttonStyles={styles.payButton}
        buttonLabel="Platește soldul"
        makePayment={makePayment}
      /> */}
        {/* <div className="subMenuHover" style={} onClick={makePayment}>
        Platește soldul
      </div> */}
      </div>
    )
  else
    return [
      <div key={1} className={containerClassName + " cfdMobileHeader"}>
        <div className="title">
          Contul tău a fost activat însă nu avem informații de afișat
        </div>
        <div className="text">
          Vei putea accesa toate funcționalitățile contului tău după ce se va
          emite prima factură de consum. Te vom anunța printr-un email când
          acest lucru se va întâmpla.
        </div>
        <Button
          className="textAlignCenter"
          as="Link"
          aspect="secondary"
          to="/"
          label="Către site public"
        />
      </div>,
      <div key={2} id="contFaraDatePage" className="templateFullContainer ">
        <div className="cfdpContent popupContainer">
          <StaticImage
            className="logo"
            loading="eager"
            layout="fullWidth"
            src="../../../images/logoNouTransparent.png"
            alt="Logo gazmir"
          />
          <div className="text">
            <div className="popupTitle">
              Contul tău a fost activat însă nu avem informații de afișat
            </div>
            <div className="text">
              Vei putea accesa toate funcționalitățile contului tău după ce se
              va emite prima factură de consum. Te vom anunța printr-un email
              când acest lucru se va întâmpla.
            </div>
          </div>
          <Button
            className="textAlignCenter"
            as="Link"
            aspect="primary"
            to="/"
            label="Către site public"
          />
        </div>
      </div>,
    ]
}

const styles = {
  container: {
    position: "absolute",
    right: "5vw",
    top: "15vh",
    width: 340,
    // height: 200,
    backgroundColor: colors.main,
  },
  title: {
    color: colors.white,
    fontSize: 20,
    height: 40,
    lineHeight: "40px",
    // paddingTop: 12,
    // paddingBottom: 12,
    textAlign: "center",
    fontWeight: "bold",
  },
  infoText: {
    float: "left",
    width: 146,
    height: 40,
    lineHeight: "40px",
    color: colors.white,
    fontSize: 20,
    marginLeft: 24,
  },
  payButton: {
    float: "left",
    backgroundColor: colors.white,
    color: colors.main,
    fontSize: 16,
    height: 28,
    width: 176,
    borderRadius: 6,
    lineHeight: "28px",
    textAlign: "center",
    margin: "6px 80px",
    cursor: "pointer",
    display: "block",
    marginBottom: 8,
  },
  spinner: {
    width: 20,
    height: 20,
    margin: "10px 0px 0px 30px",
  },
  input: {
    border: "none",
    // marginLeft: 10,
    marginTop: 6,
    height: 28,
    width: "calc(100% - 10px)",
    fontSize: 14,
    paddingLeft: 4,
    borderRadius: 6,
  },
}
